import React from "react"
import get from "lodash/get"
import Helmet from "react-helmet"
import {graphql} from "gatsby"

import LocalizedLink from "../components/LocalizedLink"
import Layout from "../components/layout"

class About extends React.Component {
  render() {
    const siteTitle = get(this, "props.data.site.siteMetadata.title")

    return (
      <Layout location={this.props.location}>
        <Helmet title={siteTitle}>
          <meta
            name="description"
            content="A Game Studio is the
          culmination of a work of research, learning, design and development,
          put together to transform a creative passion into professional game development."
          />
        </Helmet>
        <h1>About A Game Studio</h1>
        <h2>History</h2>
        <p>
          Game creator since 2010, the birth of A Game Studio in 2018 is the
          culmination of a work of research, learning, design and development,
          put together to transform a creative passion into professional game
          development.
        </p>
        <p>
          A Game Studio first game – «{" "}
          <LocalizedLink to="/projects/a-time-paradox">
            A Time Paradox
          </LocalizedLink>{" "}
          » – is to be released by october 2018 on PC and Android.
        </p>

        <h2>Contact</h2>
        <ul>
          <li>
            By email:{" "}
            <a href="mailto:contact@a-game-studio.com">
              contact@a-game-studio.com
            </a>
          </li>
          <li>
            On twitter:{" "}
            <a href="https://twitter.com/agamestudio_">@agamestudio_</a>
          </li>
          <li>
            IRL: at{" "}
            <a href="https://www.meetup.com/fr-FR/Game-Dev-Afterwork-Nantes/">
              Game Dev Afterwork in Nantes
            </a>
          </li>
        </ul>
        <h2>The Team</h2>
        <ul>
          <li>Lythom (Samuel Bouchet) — Founder and video game craftsman</li>
        </ul>
        <h3>With the help of</h3>
        <ul>
          <li>Agathe Robache – Writting</li>
          <li>Jaqueline Florencio – Illustration, Concept Art</li>
          <li>Magali Ploteau – Game Design, Level Design, Tests</li>
          <li>Marie Bouchet – Character design</li>
          <li>Philippe Salib (Lonestone studio) – Pixel art</li>
        </ul>
      </Layout>
    )
  }
}

export const pageQuery = graphql`
  query AboutQueryEN {
    site {
      siteMetadata {
        title
      }
    }
  }
`

export default About
